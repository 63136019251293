<template>
  <v-card flat>
    <Loader absolute :loading="loading" />
    <v-card-title>
      <v-icon left>mdi-account</v-icon>
      Nuevo Contacto
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-row class="pt-4">
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field
              v-model="contact.name"
              :label="$t('name')"
              :rules="requiredRule"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field
              v-model="contact.last_name"
              :label="$t('lastName')"
              :rules="requiredRule"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field
              v-model="contact.email1"
              label="Email"
              :rules="requiredRule"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" md="6" sm=" 12" class="py-0">
            <v-text-field
              v-model="contact.phone1"
              label="Teléfono"
              :rules="requiredRule"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field
              v-model="address.state"
              label="Departamento"
              :rules="requiredRule"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field
              v-model="address.address1"
              label="Direccion"
              :rules="requiredRule"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" md="12" sm="12" class="py-0">
            <v-textarea
              v-model="contact.notes"
              label="Notas"
              rows="2"
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        small
        color="grey"
        @click="cancel"
      >
        <v-icon class="hidden-md-and-up">mdi-close</v-icon>
        <span class="hidden-sm-and-down">{{ $t('cancel') }}</span>
      </v-btn>
      <v-btn
        small
        color="success"
        @click="saveCustomer"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ContactServices from '@/store/services/general/ContactServices'
// import AddressServices from '@/store/services/general/AddressServices'
import { mapGetters } from 'vuex';
export default {
  props: {
    contactData: {
      default: null
    },
    model: {
      default: null,
      required: true
    },
    modelId: {
      default: 0,
      required: true
    }
  },
  data() {
    return {
      e1:1,
      contact: {
        id: '',
        contactable_type: '',
        contactable_id: '',
        user_id: '',
        name: '',
        last_name: '',
        ic: '',
        email1: '',
        email2: '',
        phone1: '',
        phone2: '',
        phone3: '',
        description: '',
        type: 'MAIN',
        status: 'ACTIVE',
      },
      // address
      address: {
        id: '',
        addressable_type: 'contact',
        addressable_id: '',
        address1: '',
        address2: '',
        addressable: '',
        country: 'Bolivia',
        state: '',
        city: '',
        zip_code: '0',
        latitude: '',
        longitude: '',
      },
      requiredRule: [
        v => !!v || this.$t('requiredField')
      ],
      loading: false
    }
  },
  methods: {
    cancel() {
      this.$emit('onCancel')
    },
    async saveCustomer() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          const data = {
            contact: this.contact,
            address: this.address,
          }
          // var responseContact = ''
          // var addressResponse = ''
          if (!this.contactData) {
            this.contact.contactable_type = this.model
            this.contact.contactable_id = this.modelId
            // Save Contact
            let dataFormContact = new FormData()
            for (let key in this.contact) {
              dataFormContact.append(key, this.contact[key])
            }
            await ContactServices.store(this.user.archon.id, data)
  
            // Save Supplier
            // this.supplier.entity_id = responseEntity.data.id
            // let dataFormSupplier = new FormData()
            // for (let key in this.supplier) {
            //   dataFormSupplier.append(key, this.supplier[key])
            // }
            // responseSupplier = await SupplierServices.store(this.user.archon.id, dataFormSupplier)
          } else {
            this.contact.address = this.address
            await ContactServices.update(this.user.archon.id, this.contact.id, this.contact)
  
            // await AddressServices.update(this.user.archon.id, this.address.id, this.address)
            // this.address = addressResponse.data
          }
          this.loading = false;
          this.$emit('onCreated')
  
          this.resetData();
        } catch (error) {
          this.loading = false
          console.log(error)
        }
      }
    },
    setContact() {
      if (this.contactData) {
        // Set Contact
        this.contact.id = this.contactData.id
        this.contact.contactable_type = this.contactData.contactable_type
        this.contact.contactable_id = this.contactData.contactable_id
        this.contact.user_id = this.contactData.user_id
        this.contact.name = this.contactData.name
        this.contact.last_name = this.contactData.last_name
        this.contact.ic = this.contactData.ic
        this.contact.email1 = this.contactData.email1
        this.contact.email2 = this.contactData.email2
        this.contact.phone1 = this.contactData.phone1
        this.contact.phone2 = this.contactData.phone2
        this.contact.phone3 = this.contactData.phone3
        this.contact.description = this.contactData.description
        this.contact.notes = this.contactData.notes
        // Set Address
        this.address.id = this.contactData.address.id
        this.address.addressable_type = this.contactData.address.addressable_type
        this.address.addressable_id = this.contactData.address.addressable_id
        this.address.address1 = this.contactData.address.address1
        this.address.address2 = this.contactData.address.address2
        this.address.addressable = this.contactData.address.addressable
        this.address.country = this.contactData.address.country
        this.address.state = this.contactData.address.state
        this.address.city = this.contactData.address.city
      }
    },
    resetData() {
      this.contact = {
        id: '',
        contactable_type: 'entity',
        contactable_id: '',
        user_id: '',
        name: '',
        last_name: '',
        ic: '',
        email1: '',
        email2: '',
        phone1: '',
        phone2: '',
        phone3: '',
        description: '',
        type: 'MAIN',
        status: 'ACTIVE',
      };
      // address
      this.address = {
        id: '',
        addressable_type: 'entity',
        addressable_id: -1,
        address1: '',
        address2: '',
        addressable: '',
        country: 'Bolivia',
        state: '',
        city: '',
        zip_code: '0',
        latitude: '',
        longitude: '',
        notes: '',
      };

      this.$refs.form.resetValidate();
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    dataAddressIsValid() {
      return (
        this.address.address1 &&
        this.address.country &&
        this.address.state &&
        this.address.city
      )
    },
    dataContactIsValid() {
      return (
        this.contact.name &&
        this.contact.last_name &&
        this.contact.email1 &&
        this.contact.phone1 
      )
    }
  },
  watch: {
    contactData: {
      immediate: true,
      deep: true,
      handler: 'setContact'
    },
    'address.state'(value) {
      this.address.city = value;
    },
  }
}
</script>

<style scoped>
  .v-stepper__header {
    height: 55px;
    align-items: center;
  }
  .v-stepper__step {
    padding-top: 2.5%;
    padding-bottom: 2.5%;
  }
  .v-stepper__content {
    padding-top: 10px !important;
  }
</style>