<template>
  <div>
    <v-card>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <div style="width: 150px;">
            <v-select
              v-model="perPage"
              label="Total visible"
              :items="listPerPage"
              @change="resetCurrentPage"
              dense
            />
          </div>
          <div>
            <v-btn
              @click="getContacts"
              color="warning"
              small
              text
            >
              <v-icon left>mdi-refresh</v-icon>
              Refrescar
            </v-btn>
            <!-- <v-btn
              @click="createCustomer"
              color="primary"
              small
              text
            >
              <v-icon left>mdi-plus</v-icon>
              {{$t('customer')}}
            </v-btn> -->
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :items="contacts"
          :loading="loading"
          disable-pagination
          hide-default-footer
          fixed-header
          @click:row="selectSupplier"
        >
          <template v-slot:header.level="{ header }">
            <div>
              {{ header.text }}
            </div>
            <div class="px-2">
              <input
                type="text"
                v-model="searchLevel"
                class="input__search"
                @keyup.enter="resetCurrentPage"
              >
              <v-btn icon small @click="searchLevel !== '' && resetSearch('level')">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                link
                @click="editEntity(item)"
              >
                <v-icon left>mdi-pencil</v-icon>
                <small>Editar</small>
              </v-list-item>
              <!-- <v-list-item
                link
                @click="removeNegotiation(item)"
              >
                <v-icon left>mdi-trash-can</v-icon>
                <small>Eliminar</small>
              </v-list-item> -->
              
            </v-list>
          </v-menu>
        </template>
          
          <template v-slot:item.name="{ item }">
            <div>
              <div>{{ item.name }} {{ $t(item.last_name) }}</div>
            </div>
          </template>
        </v-data-table>
        <div class="text-center">
          <v-pagination
            v-model="currentPage"
            :disabled="loading"
            :length="lastPage"
            :total-visible="8"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ContactServices from '@/store/services/general/ContactServices'
import ListMixin from '@/mixins/commons/ListMixin';
import { mapGetters } from 'vuex';
export default {
  mixins: [ListMixin],
  props: {
    entityId: {
      default: 0
    }
  },
  data() {
    return {
      contacts: [],
      loading: false,
      suppliers: [],
      currentSupplier: {
        id: -1,
        children: [],
      },
    }
  },
  methods: {
    async getContacts() {
      try {
        this.loading = true;
        const params = {
          order_by: 'name',
          order_direction: 'ASC',
          page: 1,
          per_page: 50,
          include: 'address',
          models: ['entity'],
          modelsId: [this.entityId]
        };
        const response = await ContactServices.index(
          this.user.archon.id,
          params
        )
        this.contacts = response.data.data
        this.currentPage = response.data.current_page
        this.lastPage = response.data.last_page
        this.total = response.data.total
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    selectSupplier(value) {
      this.currentSupplier = value
    },
    editEntity(value) {
      this.$emit('onEdit', value)
    },
    resetCurrentPage() {
      if (this.currentPage === 1) this.getContacts();
      else this.currentPage = 1;
    },
    createCustomer() {
      this.$emit('onNew')
    },
    setEntityId() {
      if (this.entityId > 0) {
        this.getContacts()
      }
    }
  },
  watch: {
    entityId: {
      immediate: true,
      handler: 'setEntityId'
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          value: 'actions',
          sortable: false,
        },
        {
          text: 'Nombre',
          value: 'name',
          sortable: false,
          width: '25%'
        },
        {
          text: 'Correo',
          value: 'email1',
          sortable: false,
          width: '25%'
        },
        {
          text: 'Teléfono',
          value: 'phone1',
          sortable: false,
          width: '25%'
        },
        {
          text: 'Dirección',
          value: 'address.address1',
          sortable: false,
          width: '25%'
        },
      ];
    },
  },
  created() {
    this.getContacts()
  }
}
</script>

<style>

</style>