<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <SupplierList
          ref="supplierList"
          @onNewSupplier="addSupplier"
          @onNewContact="addContact"
          @onEdit="editSupplier"
          @onSelectSupplier="supplierSelected"
        />
        <v-dialog
          v-model="modalSupplier"
          width="700"
          persistent
        >
          <SupplierForm
            ref="supplierForm"
            @onCreated="supplierCreated"
            @onCancel="cancelSupplier"
          />
        </v-dialog>
      </v-col>
      <v-col cols="12" md="6">
        <ContactList
          ref="contactList"
          :entityId="entityId"
          @onEdit="editContact"
        />
        <v-dialog
          v-model="modalContact"
          width="700"
          persistent
        >
          <ContactForm
            @onCreated="contactCreated"
            @onCancel="cancelContact"
            :contactData="contact"
            :model="modelType"
            :modelId="modelId"
          />
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SupplierList from './SupplierList'
import SupplierForm from './SupplierForm'
import ContactList from '../contact/ContactList'
import ContactForm from '../contact/ContactForm'
export default {
  components: {
    SupplierList,
    SupplierForm,
    ContactList,
    ContactForm
  },
  data() {
    return {
      modalSupplier: false,
      modalContact: false,
      entityId: 0,
      modelType: 'entity',
      modelId: 0,
      supplier: null,
      contact: null
    }
  },
  methods: {
    addSupplier() {
      this.modalSupplier = true
    },
    supplierCreated(data) {
      if (data.type === 'store') this.$refs.supplierList.addSupplier(data.supplier);
      else this.$refs.supplierList.updateSupplier(data.supplier);
      this.modalSupplier = false
    },
    supplierSelected(value) {
      this.entityId = value.entity_id
    },
    cancelSupplier() {
      this.modalSupplier = false
    },
    editSupplier(value) {
      try {
        this.modalSupplier = true;
        setTimeout(() => {
          const supplier = Object.assign({}, value.supplier);
          const entity = Object.assign({}, value.supplier.entity);
          this.$refs['supplierForm'].entity = entity;
          this.$refs['supplierForm'].supplier = supplier;
          this.$refs['supplierForm'].supplierId = supplier.id;
          this.$refs['supplierForm'].entityId = entity.id;
          this.$refs['supplierForm'].edit = true;
        }, 500);
      } catch (error) {
        console.log(error)
      }
    },
    addContact(value) {
      this.modalContact = true
      this.entityId = value.entity_id
      this.modelId = value.entity_id
    },
    contactCreated() {
      this.modalContact = false
      this.$refs.contactList.getContacts()
    },
    cancelContact() {
      this.modalContact = false
    },
    editContact(value) {
      console.log(value)
      this.contact = value
      this.modalContact = true
    }
  }
}
</script>

<style>

</style>